import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import scrollTo from "gatsby-plugin-smoothscroll"
import StarRating from "react-svg-star-rating"
import { Link } from "gatsby"
import styled from "styled-components"
import ReviewContainer from "../components/styles/ReviewContainer"
import MainButton from "../components/styles/MainButton"
import RelatedBikes from "../components/RelatedBikes"

class BikeReview extends React.Component {
  render() {
    const affiliateLink = "https://www.roguefitness.com/assault-airbike-and-accessories?a_aid=5e7fb64738ac3"
    const linkText = "$699 from Rogue Fitness (30% Off Now)"

    return (
      <Layout>
        <SEO
          title="Assault Air Bike In-Depth Review 2020"
          description="This heavy-duty steel-framed bike is built to last, and delivers an intense workout. Perfect for your home gym."
        />
        <ReviewContainer>
          <div className="r-post">
            <div>
              <aside className="r-article-toc">
                <div className="product-name">
                  <p className="article-type">In-Depth Review</p>
                  <h2>Assault AirBike</h2>
                  <p>Updated Dec 3rd, 2020</p>
                </div>
                <div>
                  <div className="r-article-toc--btn">Table of Contents</div>
                </div>
                <div className="r-article-toc--contents">
                  <ul>
                    <li>
                      <span onClick={() => scrollTo("#n0")}>Review</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n1")}>
                        Pros and Cons of Assault AirBike
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n2")}>
                        What sets the Assault AirBike Apart?
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n3")}>
                        Assault AirBike Comparison
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n4")}>
                        Assault AirBike Rating
                      </span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n6")}>Reviews</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n7")}>Common Questions</span>
                    </li>
                    <li>
                      <span onClick={() => scrollTo("#n5")}>Where to buy</span>
                    </li>
                  </ul>
                </div>
                <div className="r-article-toc--cta">
                  <MainButton
                    href={affiliateLink}
                    target="_blank"
                  >
                    {linkText}
                  </MainButton>
                </div>
              </aside>
            </div>
            <div className="r-post-content">
              <div className="r-post-container">
                <div className="r-product-box">
                  <div className="product-box-brand">Assault AirBike</div>
                  <div className="product-left">
                    <img
                      src="/images/assault-airbike/assault-airbike-side.png"
                      alt="Assault AirBike"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Assault AirBike
                    </a>
                    <p>
                      This heavy-duty steel-framed bike is built to last, and
                      delivers an intense workout. Perfect for your home gym.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
                <p className="r-post-intro">
                  The <span className="brand-name">Assault AirBike</span> is a
                  heavy-duty exercise bike designed directly from the feedback
                  of athletes and coaches. The Assault AirBike reinvents and
                  retools nearly every component of the traditional fan bike,
                  from the frame construction to the crank, pedals, monitor and
                  more.
                </p>
                <span id="n0"></span>
                <h2>Review</h2>
                <p>
                  The Assault AirBike has pretty much become the standard air
                  bike among those that train for CrossFit. That’s due to the
                  bike’s superior performance compared to other Air bikes on the
                  market currently. This heavy-duty steel-framed bike is built
                  to last, and delivers an intense workout. Perfect for your
                  home gym.
                </p>
                <p>
                  The Assault AirBike supports many different workouts for all
                  skill levels from beginner to more advanced styles of interval
                  training. Using air resistance, the Assault AirBike scales
                  automatically to how hard you want to work. The harder you
                  pedal, the greater the resistance.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-airbike-front-view.png"
                  alt="Assault AirBike"
                />
                <p>
                  The great thing about the Assault AirBike is that it can work
                  for a beginner, a rehabbing athlete, or a seasoned pro
                  training at the highest levels of competition—no matter their
                  sport, body type, or ambition. While fanbikes have existed for
                  nearly half a century, modern advances in their design and
                  function have made them increasingly in-demand among military
                  personnel, pro and college sports teams, competitive cyclists
                  and sprinters, and high performance athletes from the
                  cross-training ranks. The key: you set your own pace. The
                  resistance adapts to your own output, and the bike’s multiple,
                  custom seat adjustments make for a more comfortable,
                  customized ride.
                </p>
                <p>
                  The Assault AirBike comes with one of the best LCD display
                  consoles featured on Air Bikes currently. Athletes can
                  simultaneously track their heart rate, speed, RPM, time,
                  calories burned, and more. Even watts can be monitored with
                  precision accuracy, thanks to the AirBike’s 20-horsepower
                  electric motor and carefully calibrated torque measurement
                  meter.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-airbike-console2.png"
                  alt="Assault AirBike Console"
                />
                <p>
                  Overall, the Assault AirBike is one of the most top rated Air
                  Bikes on market and has been rigorously tested in CrossFit
                  competitions for many years. It’s built incredibly tough and
                  requires almost zero wobble and zero maintenance over
                  thousands of hours of use.
                </p>
                <span id="n1"></span>
                <h2>Pros and Cons of Assault AirBike</h2>
                <h3>Positives:</h3>
                <ul className="r-content-list-positives">
                  <li>
                    Used and tested throughout the CrossFit Games for many years
                    now.
                  </li>
                  <li>
                    The monitor is very well liked and displays a variety of
                    metrics in an easy to read and use way.
                  </li>
                  <li>
                    Most of the Assault Bike is metal which makes it very sturdy
                    and a very durable Air Bike.
                  </li>
                  <li>The seat is comfortable and durable.</li>
                  <li>
                    The handles are made of rubber grips and have held up very
                    well from very intense workouts.
                  </li>
                </ul>
                <h3>Negatives:</h3>
                <ul className="r-content-list-negatives">
                  <li>
                    Uses a chain-drive instead of belt which is bad for
                    durability and noise compared to other competitors.
                  </li>
                  <li>
                    The AirBike isn't as sturdy and stable as the Rogue Echo
                    Bike.
                  </li>
                  <li>
                    Depending on workout intensity, screws may come loose and
                    may require re-tightening.
                  </li>
                </ul>
                <span id="n2"></span>
                <h2>What sets the Assault AirBike Apart?</h2>
                <h3>Assault AirBike Features</h3>
                <h4>1. INSTANT CUSTOMIZATION</h4>
                <p>
                  The great thing about the Assault AirBike is that it can work
                  for a beginner, a rehabbing athlete, or a seasoned pro
                  training at the highest levels of competition—no matter their
                  sport, body type, or ambition. While fanbikes have existed for
                  nearly half a century, modern advances in their design and
                  function have made them increasingly in-demand among military
                  personnel, pro and college sports teams, competitive cyclists
                  and sprinters, and high performance athletes from the
                  cross-training ranks. The key: you set your own pace. The
                  resistance adapts to your own output, and the bike’s multiple,
                  custom seat adjustments make for a more comfortable,
                  customized ride.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-aribike-customization.png"
                  alt="Assault AirBike"
                />

                <h4>2. ADVANCED TECH AND LCD DISPLAY</h4>
                <p>
                  In the age of smartphones and tablets, an old-fashioned,
                  clunky fitness bike monitor simply doesn’t cut it. The Assault
                  AirBike’s state-of-the-art LCD display console is still easy
                  to navigate at the push of a button, but your customization
                  options—from on-board programs to instant, multi-faceted
                  monitor feedback—is almost limitless. Athletes can
                  simultaneously track their heart rate, speed, RPM, time,
                  calories burned, and more. Even watts can be monitored with
                  precision accuracy, thanks to the AirBike’s 20-horsepower
                  electric motor and carefully calibrated torque measurement
                  meter. You won’t just feel the effects of a good workout,
                  you’ll be able to see the science behind it in real time.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-aribike-display.png"
                  alt="Assault AirBike"
                />

                <h4>3. QUALITY CONSTRUCTION, MAX DURABILITY</h4>
                <p>
                  LifeCORE threw out many of the old stationary bike
                  manufacturing methods and focused on a powerful new 21st
                  century bike that could handle daily abuse at the best gyms in
                  the world. Each bike is equipped with a thick-gauge chro-moly
                  steel frame, with at least 1-2 sealed cartridge bearings in
                  EVERY single pivot—from the flywheels and pedals to the bottom
                  bracket and linkage arms. There are over 20 bearings in all,
                  unmatched by most fitness bikes in the industry. The goal
                  isn’t just reliability—it’s zero wobble and zero maintenance
                  required over thousands of hours of use.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-aribike-construction.png"
                  alt="Assault AirBike"
                />

                <h4>4. EASY ASSEMBLY</h4>
                <p>
                  The Assault AirBike ships with everything you need for a
                  quick, one-and-done assembly. This includes an instruction
                  manual with clear-cut guidelines to get you up and running.
                  We’ve also provided this step-by-step instructional video
                  above to give you an even easier way to visualize and follow
                  the set-up process.
                </p>
                <img
                  src="/images/assault-airbike/features/assault-aribike-assembly.png"
                  alt="Assault AirBike"
                />

                <span id="n3"></span>
                <h2>Assault AirBike Comparison</h2>
                <h3>
                  Assault AirBike vs Rogue Echo Bike vs Schwinn Airdyne Pro
                </h3>
                <p>
                  The Assault AirBike is a heavy-duty exercise bike designed
                  directly from the feedback of athletes and coaches. The
                  Assault AirBike reinvents and retools nearly every component
                  of the traditional fan bike, from the frame construction to
                  the crank, pedals, monitor and more.
                </p>
                <img src="/images/airbike-prices.png" className="price-graph" alt="Air bike prices graph"/>
                <p>
                  The Assault AirBike utilizes a chain driven system which will
                  produce much more noise when working the bike to peak
                  performance compared to the Rogue Echo Bike and Schwinn
                  AirDyne. The chain driven system also isn’t as durable as the
                  belt driven counterparts and may require some additional
                  maintenance and chain oil every few workouts.
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td className="table-bold">Brand:</td>
                      <td className="table-bold">Schwinn AD Pro</td>
                      <td className="table-bold">Assault AirBike</td>
                      <td className="table-bold">Rogue Echo Bike</td>
                    </tr>
                    <tr>
                      <td>Price:</td>
                      <td>$1,299</td>
                      <td>$999</td>
                      <td>$795</td>
                    </tr>
                    <tr>
                      <td>Weight:</td>
                      <td>113 lbs.</td>
                      <td>98 lbs.</td>
                      <td>127 lbs.</td>
                    </tr>
                    <tr>
                      <td>Drive System:</td>
                      <td>Belt-Driven</td>
                      <td>Chain</td>
                      <td>Belt-Driven</td>
                    </tr>
                    <tr>
                      <td>Foot Pegs:</td>
                      <td>Rotating Plastic/Metal Pegs</td>
                      <td>Knurled Stationary Pegs</td>
                      <td>Rotating Metal Pegs</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td>Metal</td>
                      <td>Plastic</td>
                      <td>Metal</td>
                    </tr>
                    <tr>
                      <td>Monitor Presets:</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                      <td>Time, Distance, Calories, Intervals, Heart Rate</td>
                    </tr>
                    <tr>
                      <td>Pedals:</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Adjustments:</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                      <td>Forward, Back, Up, Down Seat Adjustments</td>
                    </tr>
                    <tr>
                      <td>Length:</td>
                      <td>42"</td>
                      <td>50.9"</td>
                      <td>58.875"</td>
                    </tr>
                    <tr>
                      <td>Width:</td>
                      <td>20"</td>
                      <td>23.3"</td>
                      <td>29.875"</td>
                    </tr>
                    <tr>
                      <td>Height:</td>
                      <td>52"</td>
                      <td>48.4"</td>
                      <td>52.75"</td>
                    </tr>
                  </tbody>
                </table>
                <span id="n4"></span>
                <h2>Assault AirBike Rating</h2>
                <h3>
                  Assault AirBike - <span className="score-rating">4.2</span>
                </h3>
                <StarRating
                  isHalfRating={true}
                  isReadOnly
                  initialRating={4.2}
                />
                <div className="rating__graphs" width="100%">
                  <div>
                    <p>Construction - 4.5</p>
                    <progress value="4.5" max="5"></progress>
                  </div>
                  <div>
                    <p>Durability 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Performace - 4.5</p>
                    <progress value="4.5" max="5"></progress>
                  </div>
                  <div>
                    <p>Aesthetics - 3.8</p>
                    <progress value="3.8" max="5"></progress>
                  </div>
                  <div>
                    <p>Customization - 4</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Adjustability - 4.0</p>
                    <progress value="4" max="5"></progress>
                  </div>
                  <div>
                    <p>Value - 4.5</p>
                    <progress value="4.2" max="5"></progress>
                  </div>
                </div>
                <p>
                  The the Assault AirBike is one of the most top rated Air Bikes
                  on market and has been rigorously tested in CrossFit
                  competitions for many years. It’s built incredibly tough and
                  requires almost zero wobble and zero maintenance over
                  thousands of hours of use.
                </p>
                <span id="n6"></span>
                <h2>Reviews</h2>
                <h3>Positive Reviews:</h3>
                <p><span className="review-subject">"Makes it very easy to work very hard. </span>We use it in our PT studio. Both trainers and clients love to hate it. The bike is easy to assemble, robust and does exactly what is expected. For us the primary function is to use it for HiiT bursts. It accomplishes this very well. Whether you are a health enthusiast or a fitness pro you will not be disappointed. It is a simple tool doing a simple job well. Based on 3 weeks of regular use."</p>
                <p><span className="review-subject">"A tough machine. </span>Researched this product against the Airdyne AD6 and concluded that this bike s simply a more robust unit. We have an international rugby player and an academy player in my family using it regularly, so it has to be well built. So far so good on short, fast Tabata's."</p>
                <p><span className="review-subject">"Love it!!!! </span>Absolutely amazing, the piece of gym equipment you will love to hate!!"</p>

                <h3>Negative Reviews:</h3>
                <p><span className="review-subject negative">"Sadly very disapponting </span>I really really wanted to like it but after two months of usage - 3 times a week for about 1 hour the bike is not only developing odd noises that were not evident in the first 60 days of use it is also just falling apart.
                Specifically the hinge that holds the left handle starts moving and comes out - making the handle flimsy and unusable if not about to fall off.I use the dedicated tool to strengthen the hinge so many times and to no avail- the situation is so bad that if i use the bike (with the handles) I need to dismount every 3 minutes to strengthen the hinge over and over and over and over again...that is no way to use the bike making it useless. A waste of money."</p>
                <p><span className="review-subject negative">"Multiple faulty chains and bad costumer service </span>We bought five bikes. Within the first minute a chain on one broke. They sent us a new chain but zero instructions on how to put it on. Then literally 20 mins after getting off the phone with an uncooperative customer service rep the chain on a second bike broke. It was the very next serial number in order so it must have been a factory issue. Got back on with costumer service who basically told me to just deal with it and refused to let me speak with a supervisor. Had to waist a ton of time and pay someone to fix them. I Should have spent the extra $50 and gone with a Rogue I guess."</p>
                <p><span className="review-subject negative">"Allegedly commercial grade product broke in under thirty seconds </span>Get this assembled relatively easily. Started using it and less than thirty seconds in one of the chains snaps. They are sending me a new chain but I'm on my own to fix it."</p>
                <h3>Assault AirBike Review by Josh Bridges</h3>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/nV7iZSdDclE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <span id="n7"></span>
                <h2>Common Questions</h2>
                <p><span className="common-question">Are Air Assault bikes good? </span>It could potentially be a great low impact tool because it offers healthy movement without harm. It also makes it easier to move around any injury to provide blood supply to the area to help in healing. As it can help break through plateaus in your preparation, the Assault Air Bike is also great for metabolic conditioning.</p>
                <p><span className="common-question">Does the assault bike build muscle? </span>You can also use your Assault Bike for muscle building with the right fitness program. Extra high-intensity sessions can improve flexibility, stamina, and strength. At the same time, it is also helpful for your stamina to move between high-intensity and mid-intensity workouts.</p>
                <p><span className="common-question">Are air bikes worth it? </span>Even popping on Netflix and cranking out a couple of miles casually is a perfect way to sneak in your cardio. Just as essential, it can also improve your attitude using an Air Bike. If you've ever used a high-intensity air bike, you know it's not fast.</p>
                <p><span className="common-question">Is the air bike good for weight loss? </span>It will assist in weight loss. You can burn over 600 calories an hour with a stationary bike workout, depending on the strength of your workout and your body weight. This makes indoor cycling a perfect exercise choice to rapidly burn calories. The trick to weight loss is to eat more calories than you consume.</p>
                <span id="n5"></span>
                <h2>Where to buy</h2>
                <div className="r-product-box">
                  <div className="product-box-brand">Assault AirBike</div>
                  <div className="product-left">
                    <img
                      src="/images/assault-airbike/assault-airbike-side.png"
                      alt="Assault AirBike"
                    />
                  </div>
                  <div className="product-right">
                    <a
                      href={affiliateLink}
                      target="_blank"
                    >
                      Assault AirBike
                    </a>
                    <p>
                      This heavy-duty steel-framed bike is built to last, and
                      delivers an intense workout. Perfect for your home gym.
                    </p>
                    <div>
                      <MainButton
                        href={affiliateLink}
                        target="_blank"
                      >
                        {linkText}
                      </MainButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ReviewContainer>
        <RelatedBikes 
          bike1="Rogue Echo Bike"
          bike1img="/images/rogue-echo-bike.jpg"
          bikeLink1="/rogue-echo-bike-review"

          bike2="Concept2 BikeErg"
          bike2img="/images/bike-erg.jpg"
          bikeLink2="/concept2-bike-erg-review"

          bike3="Schwinn AirDyne Pro"
          bike3img="/images/schwinn-airdyne-ad7.png"
          bikeLink3="/schwinn-airdyne-pro-review"
        />
      </Layout>
    )
  }
}

export default BikeReview
